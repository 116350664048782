// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-page-templates-post-template-js": () => import("/opt/build/repo/src/components/page-templates/post-template.js" /* webpackChunkName: "component---src-components-page-templates-post-template-js" */),
  "component---src-pages-about-me-js": () => import("/opt/build/repo/src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("/opt/build/repo/src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-styles-js": () => import("/opt/build/repo/src/pages/styles.js" /* webpackChunkName: "component---src-pages-styles-js" */),
  "component---src-pages-work-together-js": () => import("/opt/build/repo/src/pages/work-together.js" /* webpackChunkName: "component---src-pages-work-together-js" */)
}

